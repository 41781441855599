var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col relative"},[_c('div',{staticClass:"h-auto filter mb-5"},[_c('div',{staticClass:"w-full flex items-center"},[(_vm.$route.query.view)?_c('router-link',{staticClass:"text-blue-500 flex items-center",attrs:{"to":{ name: 'formResult' }}},[_c('Icons',{staticClass:"mr-1 bg-stroke",attrs:{"icon-name":"shevron"}}),_c('span',{staticClass:"text-lg font-extrabold"},[_vm._v("Назад")])],1):_vm._e(),_c('h1',{staticClass:"text-xl font-extrabold sm:text-2xl",class:{ 'ml-4': _vm.$route.query.view }},[_vm._v(" "+_vm._s(_vm.$route.query.edite ? 'Редактировать анкету' : _vm.$route.query.view ? 'Просмотр анкеты' : 'Добавить анкету')+" ")])],1)]),_c('div',{staticClass:"rounded-lg rounded-b-lg h-auto bg-white filter shadow-default"},[_c('div',{staticClass:"flex flex-col gap-8 p-7"},[_c('div',{staticClass:"w-full flex items-end gap-5"},[(!_vm.getLoadPage)?_c('div',{staticClass:"w-72 add-form__select"},[_c('label',{staticClass:"block mb-3 text-textGray font-bold text-sm leading-5"},[_vm._v("Тип анкеты")]),_c('multiselect',{attrs:{"tag-placeholder":"","placeholder":"","show-labels":false,"options":_vm.typeOptions,"searchable":false,"multiple":false,"label":"name","disabled":_vm.accessOnlyView,"track-by":"id","allow-empty":false},on:{"input":function($event){return _vm.inlineSave(
								{
									id: _vm.getForm.id,
									type: _vm.getForm.type.id,
									status: _vm.getForm.status,
									name: _vm.getForm.name,
									setCalculation: !_vm.getForm.setCalculation ? _vm.getForm.setCalculation : _vm.getForm.setCalculation === 'Да' ? true : false,
									comment: _vm.getForm.comment,
									isPublic: !_vm.getForm.isPublic ? _vm.getForm.isPublic : _vm.getForm.isPublic === 'Да' ? true : false,
									hasComent: !_vm.getForm.hasComent ? _vm.getForm.hasComent : _vm.getForm.hasComent === 'Да' ? true : false,
									places: _vm.getForm.places,
									questions: []
								},
								'Anketa/UpdateAnketa',
								true
							)}},model:{value:(_vm.getForm.type),callback:function ($$v) {_vm.$set(_vm.getForm, "type", $$v)},expression:"getForm.type"}})],1):_vm._e(),(_vm.getLoadPage)?_c('SceletonComponent',{attrs:{"label":"Тип анкеты","type":"select","width":72,"height":12,"center":true,"border":"2","classDiv":"add-form__select"}}):_vm._e(),(!_vm.getLoadPage)?_c('div',{staticClass:"w-96 add-form__input"},[_c('TextInput',{attrs:{"placeholder":"Введите текст...","height":40,"label":"Наименование анкеты","disabled":_vm.accessOnlyView},on:{"input":function($event){return _vm.inlineSave(
								{
									id: _vm.getForm.id,
									type: _vm.getForm.type.id,
									status: _vm.getForm.status,
									name: _vm.getForm.name,
									setCalculation: !_vm.getForm.setCalculation ? _vm.getForm.setCalculation : _vm.getForm.setCalculation === 'Да' ? true : false,
									comment: _vm.getForm.comment,
									isPublic: !_vm.getForm.isPublic ? _vm.getForm.isPublic : _vm.getForm.isPublic === 'Да' ? true : false,
									hasComent: !_vm.getForm.hasComent ? _vm.getForm.hasComent : _vm.getForm.hasComent === 'Да' ? true : false,
									places: _vm.getForm.places,
									questions: []
								},
								'Anketa/UpdateAnketa',
								true
							)}},model:{value:(_vm.getForm.name),callback:function ($$v) {_vm.$set(_vm.getForm, "name", $$v)},expression:"getForm.name"}})],1):_vm._e(),(_vm.getLoadPage)?_c('SceletonComponent',{attrs:{"label":"Наименование анкеты","type":"input","width":96,"height":12,"center":true,"border":"2","classDiv":"add-form__input"}}):_vm._e()],1),_vm._l((_vm.getForm.questionBlocks),function(block,ind){return _c('div',{key:'A' + ind,staticClass:"w-full"},[_c('div',{staticClass:"flex justify-left items-center bg-blue-500 text-white rounded-t-lg"},[_c('div',{staticClass:"flex items-center ml-2 min-h-10 w-full"},[(!_vm.accessOnlyView)?_c('TextInput',{staticClass:"w-10/12",attrs:{"label":"","height":30,"placeholder":"Наименование блока..."},on:{"input":function($event){return _vm.inlineSave(
									{
										id: block.id,
										anketaId: block.anketaId,
										title: block.title,
										code: block.code,
										order: block.order,
										comment: block.comment
									},
									'Anketa/UpdateAnketaBlock'
								)}},model:{value:(block.title),callback:function ($$v) {_vm.$set(block, "title", $$v)},expression:"block.title"}}):_vm._e(),(_vm.accessOnlyView)?_c('span',[_vm._v(" "+_vm._s(block.title)+" ")]):_vm._e(),(!_vm.accessOnlyView)?_c('button',{staticClass:"btn_default ml-4 cursor-pointer bg-red-400 hover:bg-red-500",attrs:{"title":"Удалить блок"},on:{"click":function($event){return _vm.deleteBlock(block, ind)}}},[_c('Icons',{attrs:{"icon-name":"trash_for_btn"}})],1):_vm._e()],1)]),_c('div',{staticClass:"w-full flex flex-col gap-5 p-7 rounded-lg border-2 border-liteGray"},[_vm._l((block.questions),function(question,index){return _c('div',{key:'B' + index,staticClass:"w-full"},[_c('div',{staticClass:"w-full flex flex-col gap-5 p-7 rounded-lg border border-liteGray"},[_c('div',{staticClass:"w-full flex items-center justify-left gap-5"},[_c('div',{staticClass:"question add-form__input w-10/12"},[_c('TextInput',{attrs:{"height":40,"label":"","placeholder":"Введите вопрос...","disabled":_vm.accessOnlyView},on:{"input":function($event){return _vm.inlineSave(
												{
													id: question.id,
													anketaId: question.anketaId,
													blockId: question.blockId,
													title: question.title,
													comment: question.comment,
													blockNumber: question.blockNumber,
													answers: question.answers && question.answers.length > 0 ? question.answers : []
												},
												'Anketa/UpdateAnketaQuestion'
											)}},model:{value:(question.title),callback:function ($$v) {_vm.$set(question, "title", $$v)},expression:"question.title"}})],1),(!_vm.accessOnlyView)?_c('button',{staticClass:"w-2/12 btn_default ml-4 cursor-pointer bg-red-400 hover:bg-red-500",attrs:{"title":"Удалить вопрос"},on:{"click":function($event){return _vm.delQuestion(question.anketaId, question.id, ind, index)}}},[_c('Icons',{attrs:{"icon-name":"trash_for_btn"}})],1):_vm._e()]),_vm._l((question.answers),function(answer,indexAnswer){return _c('div',{key:'C' + indexAnswer,attrs:{"draggable":!_vm.accessOnlyView ? true : false},on:{"dragover":(e) => _vm.onDragOver(answer, indexAnswer, e),"dragend":() => _vm.finishDrag(question.answers, answer, indexAnswer)}},[_c('div',{staticClass:"w-full flex items-center justify-end gap-5"},[(!_vm.accessOnlyView)?_c('div',{staticClass:"w-1/12 flex justify-end",on:{"dragstart":(e) => _vm.startDrag(answer, e)}},[_c('Icons',{staticClass:"cursor-move",attrs:{"icon-name":"drag-drop"}})],1):_vm._e(),_c('div',{staticClass:"add-form__input",class:{ 'w-10/12': !_vm.accessOnlyView, 'w-11/12': _vm.accessOnlyView }},[_c('TextInput',{attrs:{"label":"","height":40,"disabled":_vm.accessOnlyView,"placeholder":"Введите ответ..."},on:{"input":function($event){return _vm.inlineSave(
													{
														id: answer.id,
														anketaQuestionId: answer.anketaQuestionId,
														title: answer.title,
														order: answer.order,
														code: answer.code,
														type: answer.type
													},
													'Anketa/UpdateAnketaAnswer'
												)}},model:{value:(answer.title),callback:function ($$v) {_vm.$set(answer, "title", $$v)},expression:"answer.title"}})],1),(!_vm.accessOnlyView)?_c('button',{staticClass:"w-1/12 btn_default ml-4 cursor-pointer bg-red-400 hover:bg-red-500",attrs:{"title":"Удалить блок"},on:{"click":function($event){return _vm.delAnswer(question.anketaId, answer.id, ind, index, indexAnswer)}}},[_c('Icons',{attrs:{"icon-name":"trash_for_btn"}})],1):_vm._e()]),_c('div',{staticClass:"w-full flex items-center gap-5 py-4",staticStyle:{"margin-left":"9%"}},[_c('div',{staticClass:"add-form__radio w-3/12 flex items-center"},[_c('p',{staticClass:"block font-bold text-sm text-textGray w-6/12"},[_vm._v("Зависимость вопроса")]),_c('div',{staticClass:"flex items-center w-6/12"},[_c('div',[_c('label',{staticClass:"block text-sm text-gray-500 font-normal",class:{
														'cursor-not-allowed': block.questions.length === 1 && _vm.accessOnlyView,
														'cursor-pointer': block.questions.length > 1 && !_vm.accessOnlyView
													},attrs:{"for":answer.id + 1}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(answer.isDependedFlag),expression:"answer.isDependedFlag"}],attrs:{"disabled":block.questions.length <= 1 || _vm.accessOnlyView,"type":"radio","name":answer.id + 1,"id":answer.id + 1,"value":"Да"},domProps:{"checked":_vm._q(answer.isDependedFlag,"Да")},on:{"change":function($event){return _vm.$set(answer, "isDependedFlag", "Да")}}}),_vm._v(" Да ")])]),_c('div',{staticClass:"ml-3"},[_c('label',{staticClass:"block text-sm text-gray-500 font-normal",class:{
														'cursor-not-allowed': block.questions.length === 1 && _vm.accessOnlyView,
														'cursor-pointer': block.questions.length > 1 && !_vm.accessOnlyView
													},attrs:{"for":answer.id + 2}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(answer.isDependedFlag),expression:"answer.isDependedFlag"}],attrs:{"disabled":block.questions.length <= 1 || _vm.accessOnlyView,"type":"radio","name":answer.id + 2,"id":answer.id + 2,"value":"Нет"},domProps:{"checked":_vm._q(answer.isDependedFlag,"Нет")},on:{"change":function($event){return _vm.$set(answer, "isDependedFlag", "Нет")}}}),_vm._v(" Нет ")])])])]),(answer.isDependedFlag === 'Да')?_c('div',{staticClass:"add-form__select w-4/12"},[_c('multiselect',{attrs:{"tag-placeholder":"","disabled":_vm.accessOnlyView,"placeholder":"Выберите вопрос","show-labels":false,"options":_vm.getQuestionsList,"searchable":false,"multiple":false,"label":"name","track-by":"id"},on:{"open":function($event){_vm.questIdHide = question.id;
												_vm.blockIndx = ind;},"input":function($event){return _vm.saveDependencyQuestions(_vm.$route.params.anketId, answer.id, answer.dependencyObj, 'true', answer.dependencyQuestions, ind, index, indexAnswer)}},model:{value:(answer.dependencyObj),callback:function ($$v) {_vm.$set(answer, "dependencyObj", $$v)},expression:"answer.dependencyObj"}})],1):_vm._e()])])}),_c('Button',{staticClass:"ml-28",attrs:{"text":"+ Ответ","disabled":_vm.disabledtnAddAnswer || _vm.accessOnlyView,"color":"white","size":"smoll","border":"thick"},on:{"click":function($event){return _vm.addAnswer(question, ind, index)}}})],2)])}),_c('Button',{class:{ 'cursor-wait': _vm.disabledtnAddQuestion },attrs:{"disabled":_vm.disabledtnAddQuestion || _vm.accessOnlyView,"text":"+ Добавить вопрос","color":"white","size":"medium","border":"thick"},on:{"click":function($event){return _vm.addQuestion(block.id, block.questions.length, ind)}}})],2)])}),_c('div',{staticClass:"w-full flex items-center p-7 rounded-lg border-2 border-liteGray"},[(!_vm.getLoadPage)?_c('Button',{class:{ 'cursor-wait': _vm.disabledtnAddBlock },attrs:{"disabled":_vm.disabledtnAddBlock || _vm.accessOnlyView,"text":"+ Добавить блок вопросов","color":"white","size":"medium","border":"thick"},on:{"click":_vm.addBlock}}):_vm._e(),(_vm.getLoadPage)?_c('SceletonComponent',{attrs:{"label":"","type":"btn","width":64,"height":14,"center":true,"border":"2","classDiv":""}}):_vm._e()],1),_c('div',{staticClass:"w-full flex items-end gap-8"},[(!_vm.getLoadPage)?_c('RadioGroup',{attrs:{"title":"Наличие поля для комментариев","name":"hasComent","disabled":_vm.accessOnlyView,"options":_vm.hasComentOptions},on:{"input":function($event){return _vm.inlineSave(
							{
								id: _vm.getForm.id,
								type: _vm.getForm.type.id,
								status: _vm.getForm.status,
								name: _vm.getForm.name,
								setCalculation: !_vm.getForm.setCalculation ? _vm.getForm.setCalculation : _vm.getForm.setCalculation === 'Да' ? true : false,
								comment: _vm.getForm.comment,
								isPublic: !_vm.getForm.isPublic ? _vm.getForm.isPublic : _vm.getForm.isPublic === 'Да' ? true : false,
								hasComent: !_vm.getForm.hasComent ? _vm.getForm.hasComent : _vm.getForm.hasComent === 'Да' ? true : false,
								places: _vm.getForm.places,
								questions: []
							},
							'Anketa/UpdateAnketa',
							true
						)}},model:{value:(_vm.getForm.hasComent),callback:function ($$v) {_vm.$set(_vm.getForm, "hasComent", $$v)},expression:"getForm.hasComent"}}):_vm._e(),(_vm.getLoadPage)?_c('SceletonComponent',{attrs:{"label":"Наличие поля для комментариев","type":"radiobtn","width":60,"height":5,"border":"none","center":false,"classDiv":""}}):_vm._e(),(!_vm.getLoadPage)?_c('RadioGroup',{attrs:{"disabled":_vm.accessOnlyView,"title":"Публичность анкеты","name":"isPublic","options":_vm.isPublicOptions},on:{"input":function($event){return _vm.changeIsPublic(_vm.getForm)}},model:{value:(_vm.getForm.isPublic),callback:function ($$v) {_vm.$set(_vm.getForm, "isPublic", $$v)},expression:"getForm.isPublic"}}):_vm._e(),(_vm.getLoadPage)?_c('SceletonComponent',{attrs:{"label":"Публичность анкеты","type":"radiobtn","width":38,"height":5,"border":"none","center":false,"classDiv":""}}):_vm._e(),(_vm.getForm.isPublic === 'Нет' || _vm.getForm.isPublic === false)?_c('div',{staticClass:"w-96 add-form__select"},[_c('multiselect',{attrs:{"tag-placeholder":"","placeholder":"","show-labels":false,"options":_vm.sectionOptions,"disabled":_vm.accessOnlyView,"searchable":false,"multiple":true,"limit":1,"label":"name","track-by":"id","close-on-select":false},on:{"select":function($event){return _vm.onSelect($event, _vm.sectionOptions, 'id')},"remove":function($event){return _vm.onRemove($event, _vm.sectionOptions, 'id')},"input":function($event){return _vm.inlineSave(
								{
									id: _vm.getForm.id,
									type: _vm.getForm.type.id,
									status: _vm.getForm.status,
									name: _vm.getForm.name,
									setCalculation: !_vm.getForm.setCalculation ? _vm.getForm.setCalculation : _vm.getForm.setCalculation === 'Да' ? true : false,
									comment: _vm.getForm.comment,
									isPublic: !_vm.getForm.isPublic ? _vm.getForm.isPublic : _vm.getForm.isPublic === 'Да' ? true : false,
									hasComent: !_vm.getForm.hasComent ? _vm.getForm.hasComent : _vm.getForm.hasComent === 'Да' ? true : false,
									places: _vm.getForm.isPublic === true || _vm.getForm.isPublic === 'Да' ? [] : _vm.getForm.places,
									questions: []
								},
								'Anketa/UpdateAnketa',
								true
							)}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(props.option.checked),expression:"props.option.checked"}],staticClass:"mr-1.5",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(props.option.checked)?_vm._i(props.option.checked,null)>-1:(props.option.checked)},on:{"focus":function($event){$event.preventDefault();},"change":function($event){var $$a=props.option.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(props.option, "checked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(props.option, "checked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(props.option, "checked", $$c)}}}}),_c('span',[_vm._v(_vm._s(props.option.name))])]}}],null,false,1215221471),model:{value:(_vm.getForm.places),callback:function ($$v) {_vm.$set(_vm.getForm, "places", $$v)},expression:"getForm.places"}},[_c('template',{slot:"limit"},[_c('span')])],2)],1):_vm._e(),(!_vm.getLoadPage)?_c('RadioGroup',{attrs:{"title":"Необходимость вычислений","name":"setCalculation","options":_vm.setCalculationOptions,"disabled":_vm.accessOnlyView},on:{"input":function($event){return _vm.inlineSave(
							{
								id: _vm.getForm.id,
								type: _vm.getForm.type.id,
								status: _vm.getForm.status,
								name: _vm.getForm.name,
								setCalculation: !_vm.getForm.setCalculation ? _vm.getForm.setCalculation : _vm.getForm.setCalculation === 'Да' ? true : false,
								comment: _vm.getForm.comment,
								isPublic: !_vm.getForm.isPublic ? _vm.getForm.isPublic : _vm.getForm.isPublic === 'Да' ? true : false,
								hasComent: !_vm.getForm.hasComent ? _vm.getForm.hasComent : _vm.getForm.hasComent === 'Да' ? true : false,
								places: _vm.getForm.places,
								questions: []
							},
							'Anketa/UpdateAnketa',
							true
						)}},model:{value:(_vm.getForm.setCalculation),callback:function ($$v) {_vm.$set(_vm.getForm, "setCalculation", $$v)},expression:"getForm.setCalculation"}}):_vm._e(),(_vm.getLoadPage)?_c('SceletonComponent',{attrs:{"label":"Необходимость вычислений","type":"radiobtn","width":38,"height":5,"border":"none","center":false,"classDiv":""}}):_vm._e()],1),_c('div',{staticClass:"w-full flex items-end gap-5"},[(!_vm.getLoadPage && !_vm.accessOnlyView)?_c('Button',{attrs:{"text":_vm.accessOnlyView ? 'Вернуться назад' : 'Отмена',"size":"medium","color":"white"},on:{"click":_vm.backToTable}}):_vm._e(),(_vm.getLoadPage)?_c('SceletonComponent',{attrs:{"label":"","type":"btn","width":40,"height":14,"center":true,"border":"2","classDiv":""}}):_vm._e(),(!_vm.getLoadPage && !_vm.accessOnlyView)?_c('Button',{attrs:{"text":"Сохранить","size":"medium"},on:{"click":_vm.saveAllAnkets}}):_vm._e(),(_vm.getLoadPage)?_c('SceletonComponent',{attrs:{"label":"","type":"btn","width":40,"height":14,"center":true,"border":"2","classDiv":""}}):_vm._e()],1)],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<div class="flex flex-col relative">
		<div class="h-auto filter mb-5">
			<div class="w-full flex items-center">
				<router-link :to="{ name: 'formResult' }" class="text-blue-500 flex items-center" v-if="$route.query.view">
					<Icons icon-name="shevron" class="mr-1 bg-stroke" />
					<span class="text-lg font-extrabold">Назад</span>
				</router-link>
				<h1 class="text-xl font-extrabold sm:text-2xl" :class="{ 'ml-4': $route.query.view }">
					{{ $route.query.edite ? 'Редактировать анкету' : $route.query.view ? 'Просмотр анкеты' : 'Добавить анкету' }}
				</h1>
			</div>
		</div>
		<div class="rounded-lg rounded-b-lg h-auto bg-white filter shadow-default">
			<div class="flex flex-col gap-8 p-7">
				<!-- Основная информация анкеты -->
				<div class="w-full flex items-end gap-5">
					<div v-if="!getLoadPage" class="w-72 add-form__select">
						<label class="block mb-3 text-textGray font-bold text-sm leading-5">Тип анкеты</label>
						<multiselect
							tag-placeholder=""
							placeholder=""
							:show-labels="false"
							v-model="getForm.type"
							:options="typeOptions"
							:searchable="false"
							:multiple="false"
							label="name"
							:disabled="accessOnlyView"
							track-by="id"
							:allow-empty="false"
							@input="
								inlineSave(
									{
										id: getForm.id,
										type: getForm.type.id,
										status: getForm.status,
										name: getForm.name,
										setCalculation: !getForm.setCalculation ? getForm.setCalculation : getForm.setCalculation === 'Да' ? true : false,
										comment: getForm.comment,
										isPublic: !getForm.isPublic ? getForm.isPublic : getForm.isPublic === 'Да' ? true : false,
										hasComent: !getForm.hasComent ? getForm.hasComent : getForm.hasComent === 'Да' ? true : false,
										places: getForm.places,
										questions: []
									},
									'Anketa/UpdateAnketa',
									true
								)
							"
						>
						</multiselect>
					</div>

					<SceletonComponent v-if="getLoadPage" label="Тип анкеты" type="select" :width="72" :height="12" :center="true" border="2" classDiv="add-form__select" />

					<div v-if="!getLoadPage" class="w-96 add-form__input">
						<TextInput
							placeholder="Введите текст..."
							:height="40"
							label="Наименование анкеты"
							v-model="getForm.name"
							:disabled="accessOnlyView"
							@input="
								inlineSave(
									{
										id: getForm.id,
										type: getForm.type.id,
										status: getForm.status,
										name: getForm.name,
										setCalculation: !getForm.setCalculation ? getForm.setCalculation : getForm.setCalculation === 'Да' ? true : false,
										comment: getForm.comment,
										isPublic: !getForm.isPublic ? getForm.isPublic : getForm.isPublic === 'Да' ? true : false,
										hasComent: !getForm.hasComent ? getForm.hasComent : getForm.hasComent === 'Да' ? true : false,
										places: getForm.places,
										questions: []
									},
									'Anketa/UpdateAnketa',
									true
								)
							"
						/>
					</div>

					<SceletonComponent v-if="getLoadPage" label="Наименование анкеты" type="input" :width="96" :height="12" :center="true" border="2" classDiv="add-form__input" />
				</div>

				<!-- Итерационный блок -->
				<div class="w-full" v-for="(block, ind) in getForm.questionBlocks" :key="'A' + ind">
					<!-- Header -->
					<div class="flex justify-left items-center bg-blue-500 text-white rounded-t-lg">
						<div class="flex items-center ml-2 min-h-10 w-full">
							<TextInput
								class="w-10/12"
								label=""
								:height="30"
								placeholder="Наименование блока..."
								v-if="!accessOnlyView"
								v-model="block.title"
								@input="
									inlineSave(
										{
											id: block.id,
											anketaId: block.anketaId,
											title: block.title,
											code: block.code,
											order: block.order,
											comment: block.comment
										},
										'Anketa/UpdateAnketaBlock'
									)
								"
							/>
							<span v-if="accessOnlyView">
								{{ block.title }}
							</span>
							<button v-if="!accessOnlyView" title="Удалить блок" @click="deleteBlock(block, ind)" class="btn_default ml-4 cursor-pointer bg-red-400 hover:bg-red-500">
								<Icons icon-name="trash_for_btn" />
							</button>
						</div>
					</div>
					<!-- Body -->
					<div class="w-full flex flex-col gap-5 p-7 rounded-lg border-2 border-liteGray">
						<!-- block вопросов-ответов -->
						<div class="w-full" v-for="(question, index) in block.questions" :key="'B' + index">
							<div class="w-full flex flex-col gap-5 p-7 rounded-lg border border-liteGray">
								<!-- Блок вопроса -->
								<div class="w-full flex items-center justify-left gap-5">
									<div class="question add-form__input w-10/12">
										<TextInput
											:height="40"
											label=""
											placeholder="Введите вопрос..."
											:disabled="accessOnlyView"
											v-model="question.title"
											@input="
												inlineSave(
													{
														id: question.id,
														anketaId: question.anketaId,
														blockId: question.blockId,
														title: question.title,
														comment: question.comment,
														blockNumber: question.blockNumber,
														answers: question.answers && question.answers.length > 0 ? question.answers : []
													},
													'Anketa/UpdateAnketaQuestion'
												)
											"
										/>
									</div>
									<button
										v-if="!accessOnlyView"
										title="Удалить вопрос"
										@click="delQuestion(question.anketaId, question.id, ind, index)"
										class="w-2/12 btn_default ml-4 cursor-pointer bg-red-400 hover:bg-red-500"
									>
										<Icons icon-name="trash_for_btn" />
									</button>
								</div>

								<!-- Блок итерации ответов -->
								<div
									v-for="(answer, indexAnswer) in question.answers"
									:key="'C' + indexAnswer"
									:draggable="!accessOnlyView ? true : false"
									@dragover="(e) => onDragOver(answer, indexAnswer, e)"
									@dragend="() => finishDrag(question.answers, answer, indexAnswer)"
								>
									<div class="w-full flex items-center justify-end gap-5">
										<div v-if="!accessOnlyView" class="w-1/12 flex justify-end" @dragstart="(e) => startDrag(answer, e)">
											<Icons class="cursor-move" icon-name="drag-drop" />
										</div>
										<div class="add-form__input" :class="{ 'w-10/12': !accessOnlyView, 'w-11/12': accessOnlyView }">
											<TextInput
												label=""
												:height="40"
												:disabled="accessOnlyView"
												placeholder="Введите ответ..."
												v-model="answer.title"
												@input="
													inlineSave(
														{
															id: answer.id,
															anketaQuestionId: answer.anketaQuestionId,
															title: answer.title,
															order: answer.order,
															code: answer.code,
															type: answer.type
														},
														'Anketa/UpdateAnketaAnswer'
													)
												"
											/>
										</div>
										<button
											v-if="!accessOnlyView"
											title="Удалить блок"
											@click="delAnswer(question.anketaId, answer.id, ind, index, indexAnswer)"
											class="w-1/12 btn_default ml-4 cursor-pointer bg-red-400 hover:bg-red-500"
										>
											<Icons icon-name="trash_for_btn" />
										</button>
									</div>
									<!-- Блок ответа с выбором зависимости вопроса -->
									<div class="w-full flex items-center gap-5 py-4" style="margin-left: 9%">
										<div class="add-form__radio w-3/12 flex items-center">
											<p class="block font-bold text-sm text-textGray w-6/12">Зависимость вопроса</p>

											<div class="flex items-center w-6/12">
												<div>
													<label
														:for="answer.id + 1"
														class="block text-sm text-gray-500 font-normal"
														:class="{
															'cursor-not-allowed': block.questions.length === 1 && accessOnlyView,
															'cursor-pointer': block.questions.length > 1 && !accessOnlyView
														}"
													>
														<input
															:disabled="block.questions.length <= 1 || accessOnlyView"
															type="radio"
															:name="answer.id + 1"
															:id="answer.id + 1"
															value="Да"
															v-model="answer.isDependedFlag"
														/>
														Да
													</label>
												</div>
												<div class="ml-3">
													<label
														:for="answer.id + 2"
														class="block text-sm text-gray-500 font-normal"
														:class="{
															'cursor-not-allowed': block.questions.length === 1 && accessOnlyView,
															'cursor-pointer': block.questions.length > 1 && !accessOnlyView
														}"
													>
														<input
															:disabled="block.questions.length <= 1 || accessOnlyView"
															type="radio"
															:name="answer.id + 2"
															:id="answer.id + 2"
															value="Нет"
															v-model="answer.isDependedFlag"
														/>
														Нет
													</label>
												</div>
											</div>

											<!-- <RadioGroup
												title="Зависимость вопроса"
												:disabled="block.questions.length <= 1 || accessOnlyView"
												mb="mb-0"
												:name="answer.id"
												:options="answer.isDepended"
												v-model="answer.isDependedFlag"
												@input="checkIsDependedFlag(answer.isDependedFlag, answer.dependencyQuestions, ind, index, indexAnswer)"
											/> -->
										</div>
										<div v-if="answer.isDependedFlag === 'Да'" class="add-form__select w-4/12">
											<multiselect
												@open="
													questIdHide = question.id;
													blockIndx = ind;
												"
												tag-placeholder=""
												:disabled="accessOnlyView"
												placeholder="Выберите вопрос"
												:show-labels="false"
												v-model="answer.dependencyObj"
												:options="getQuestionsList"
												:searchable="false"
												:multiple="false"
												label="name"
												track-by="id"
												@input="saveDependencyQuestions($route.params.anketId, answer.id, answer.dependencyObj, 'true', answer.dependencyQuestions, ind, index, indexAnswer)"
											>
											</multiselect>
										</div>
									</div>
								</div>

								<!-- Добавить ответ -->
								<Button
									text="+ Ответ"
									:disabled="disabledtnAddAnswer || accessOnlyView"
									class="ml-28"
									color="white"
									size="smoll"
									border="thick"
									@click="addAnswer(question, ind, index)"
								/>
							</div>
						</div>

						<!-- Добавить вопрос -->
						<Button
							:class="{ 'cursor-wait': disabledtnAddQuestion }"
							:disabled="disabledtnAddQuestion || accessOnlyView"
							text="+ Добавить вопрос"
							color="white"
							size="medium"
							border="thick"
							@click="addQuestion(block.id, block.questions.length, ind)"
						/>
					</div>
				</div>

				<!-- Кнопка добавить блок -->
				<div class="w-full flex items-center p-7 rounded-lg border-2 border-liteGray">
					<Button
						v-if="!getLoadPage"
						:class="{ 'cursor-wait': disabledtnAddBlock }"
						:disabled="disabledtnAddBlock || accessOnlyView"
						text="+ Добавить блок вопросов"
						color="white"
						size="medium"
						border="thick"
						@click="addBlock"
					/>

					<SceletonComponent v-if="getLoadPage" label="" type="btn" :width="64" :height="14" :center="true" border="2" classDiv="" />
				</div>

				<!-- Радиобаттоны -->
				<div class="w-full flex items-end gap-8">
					<RadioGroup
						v-if="!getLoadPage"
						title="Наличие поля для комментариев"
						name="hasComent"
						:disabled="accessOnlyView"
						:options="hasComentOptions"
						v-model="getForm.hasComent"
						@input="
							inlineSave(
								{
									id: getForm.id,
									type: getForm.type.id,
									status: getForm.status,
									name: getForm.name,
									setCalculation: !getForm.setCalculation ? getForm.setCalculation : getForm.setCalculation === 'Да' ? true : false,
									comment: getForm.comment,
									isPublic: !getForm.isPublic ? getForm.isPublic : getForm.isPublic === 'Да' ? true : false,
									hasComent: !getForm.hasComent ? getForm.hasComent : getForm.hasComent === 'Да' ? true : false,
									places: getForm.places,
									questions: []
								},
								'Anketa/UpdateAnketa',
								true
							)
						"
					/>
					<SceletonComponent v-if="getLoadPage" label="Наличие поля для комментариев" type="radiobtn" :width="60" :height="5" border="none" :center="false" classDiv="" />

					<RadioGroup
						v-if="!getLoadPage"
						:disabled="accessOnlyView"
						title="Публичность анкеты"
						name="isPublic"
						:options="isPublicOptions"
						v-model="getForm.isPublic"
						@input="changeIsPublic(getForm)"
					/>
					<SceletonComponent v-if="getLoadPage" label="Публичность анкеты" type="radiobtn" :width="38" :height="5" border="none" :center="false" classDiv="" />

					<div v-if="getForm.isPublic === 'Нет' || getForm.isPublic === false" class="w-96 add-form__select">
						<multiselect
							tag-placeholder=""
							placeholder=""
							:show-labels="false"
							v-model="getForm.places"
							:options="sectionOptions"
							:disabled="accessOnlyView"
							:searchable="false"
							:multiple="true"
							:limit="1"
							label="name"
							track-by="id"
							:close-on-select="false"
							@select="onSelect($event, sectionOptions, 'id')"
							@remove="onRemove($event, sectionOptions, 'id')"
							@input="
								inlineSave(
									{
										id: getForm.id,
										type: getForm.type.id,
										status: getForm.status,
										name: getForm.name,
										setCalculation: !getForm.setCalculation ? getForm.setCalculation : getForm.setCalculation === 'Да' ? true : false,
										comment: getForm.comment,
										isPublic: !getForm.isPublic ? getForm.isPublic : getForm.isPublic === 'Да' ? true : false,
										hasComent: !getForm.hasComent ? getForm.hasComent : getForm.hasComent === 'Да' ? true : false,
										places: getForm.isPublic === true || getForm.isPublic === 'Да' ? [] : getForm.places,
										questions: []
									},
									'Anketa/UpdateAnketa',
									true
								)
							"
						>
							<template slot="option" slot-scope="props">
								<input class="mr-1.5" type="checkbox" v-model="props.option.checked" @focus.prevent />
								<span>{{ props.option.name }}</span>
							</template>
							<template slot="limit">
								<span></span>
							</template>
						</multiselect>
					</div>

					<RadioGroup
						v-if="!getLoadPage"
						title="Необходимость вычислений"
						name="setCalculation"
						:options="setCalculationOptions"
						v-model="getForm.setCalculation"
						:disabled="accessOnlyView"
						@input="
							inlineSave(
								{
									id: getForm.id,
									type: getForm.type.id,
									status: getForm.status,
									name: getForm.name,
									setCalculation: !getForm.setCalculation ? getForm.setCalculation : getForm.setCalculation === 'Да' ? true : false,
									comment: getForm.comment,
									isPublic: !getForm.isPublic ? getForm.isPublic : getForm.isPublic === 'Да' ? true : false,
									hasComent: !getForm.hasComent ? getForm.hasComent : getForm.hasComent === 'Да' ? true : false,
									places: getForm.places,
									questions: []
								},
								'Anketa/UpdateAnketa',
								true
							)
						"
					/>
					<SceletonComponent v-if="getLoadPage" label="Необходимость вычислений" type="radiobtn" :width="38" :height="5" border="none" :center="false" classDiv="" />
				</div>

				<!-- Кнопки -->
				<div class="w-full flex items-end gap-5">
					<Button v-if="!getLoadPage && !accessOnlyView" @click="backToTable" :text="accessOnlyView ? 'Вернуться назад' : 'Отмена'" size="medium" color="white" />
					<SceletonComponent v-if="getLoadPage" label="" type="btn" :width="40" :height="14" :center="true" border="2" classDiv="" />

					<Button v-if="!getLoadPage && !accessOnlyView" @click="saveAllAnkets" text="Сохранить" size="medium" />
					<SceletonComponent v-if="getLoadPage" label="" type="btn" :width="40" :height="14" :center="true" border="2" classDiv="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import debounce from 'lodash.debounce';
import TextInput from '@/components/TextInput.vue';
import Multiselect from 'vue-multiselect';
import Button from '@/components/Button.vue';
import Icons from '@/components/Icons.vue';
import SceletonComponent from '@/components/Sceleton.vue';
import RadioGroup from '@/components/RadioGroup.vue';
import getters from '../store/getters';
import mutations from '../store/mutations';
import {
	getAnketa,
	getGuid,
	setQuestionBlock,
	setQuestion,
	setAnswer,
	deleteBlockForAnket,
	deleteQuestion,
	deleteAnswers,
	inlineSaveForUrl,
	setDependedQuestion,
	deleteDependedQuestion,
	changeAnswerOrder
} from '../store/actions';

export default {
	name: 'AddForm',
	components: {
		TextInput,
		Multiselect,
		Button,
		Icons,
		RadioGroup,
		SceletonComponent
	},
	computed: {
		getForm: getters.getForm,
		getLoadPage: getters.getLoadPage,
		getQuestionsList() {
			let res = [],
				block = this.getForm.questionBlocks;

			if (block) {
				for (let i = 0; i < block.length; i += 1) {
					if (i === this.blockIndx) {
						if (block[i].questions) {
							for (let j = 0; j < block[i].questions.length; j += 1) {
								if (this.questIdHide !== block[i].questions[j].id) {
									res.push({
										id: block[i].questions[j].id,
										name: block[i].questions[j].title
									});
								}
							}
						}
					}
				}
			}

			return res;
		},
		accessOnlyView() {
			return this.$route.query.view && (this.$route.query.view === 'true' || this.$route.query.view === true);
		}
	},
	data() {
		return {
			questIdHide: null,
			blockIndx: null,
			disabledtnAddQuestion: false,
			disabledtnAddBlock: false,
			disabledtnAddAnswer: false,
			typeOptions: [],
			questionOptions: [{ name: 'Вопрос 1' }, { name: 'Вопрос 2' }, { name: 'Вопрос 3' }, { name: 'Вопрос 4' }],
			sectionOptions: [],
			radioOptions: [
				{ title: 'Да', id: 'input_01', checked: false },
				{ title: 'Нет', id: 'input_02', checked: false }
			],
			hasComentOptions: [
				{ title: 'Да', id: 'input_1', checked: false },
				{ title: 'Нет', id: 'input_2', checked: false }
			],
			isPublicOptions: [
				{ title: 'Да', id: 'input_3', checked: false },
				{ title: 'Нет', id: 'input_4', checked: false }
			],
			setCalculationOptions: [
				{ title: 'Да', id: 'input_5', checked: false },
				{ title: 'Нет', id: 'input_6', checked: false }
			],
			over: {},
			startLoc: 0,
			dragging: false,
			dragFrom: {}
		};
	},
	methods: {
		updateQuestions: mutations.updateQuestions,
		updateForm: mutations.updateForm,
		changeLoadPage: mutations.changeLoadPage,
		addBlockWithAnket: mutations.addBlockWithAnket,
		addQuestionForBlock: mutations.addQuestionForBlock,
		deleteBlockForState: mutations.deleteBlockForState,
		deleteQuestionForBlock: mutations.deleteQuestionForBlock,
		addAnswerForQuest: mutations.addAnswerForQuest,
		deleteAnswerForQuest: mutations.deleteAnswerForQuest,
		changeDependens: mutations.changeDependens,
		backToTable() {
			this.$router.push({
				name: 'formResult'
			});
		},
		saveAllAnkets() {
			this.$router.push({
				name: 'formResult'
			});
		},
		onSelect(option, options) {
			let index = options.findIndex((item) => item.name === option.name);
			options[index].checked = true;
		},
		onRemove(option, options) {
			let index = options.findIndex((item) => item.name === option.name);
			options[index].checked = false;
		},
		addBlock() {
			this.disabledtnAddBlock = true;
			let index = this.getForm.questionBlocks && this.getForm.questionBlocks.length > -1 ? this.getForm.questionBlocks.length : 0;

			setQuestionBlock(this.$http, {
				anketaId: this.$route.params.anketId,
				title: '',
				order: index,
				blockNumber: index,
				comment: '',
				code: ''
			}).then((res) => {
				this.addBlockWithAnket(res.data);
				this.disabledtnAddBlock = false;
			});
		},
		deleteBlock(block, index) {
			if (confirm('Вы уверены что хотите удалить блок?')) {
				deleteBlockForAnket(this.$http, block.id).then(() => {
					this.deleteBlockForState(index);
				});
			}
		},
		addQuestion(blockId, index, blockIndex) {
			this.disabledtnAddQuestion = true;

			setQuestion(this.$http, {
				anketaId: this.$route.params.anketId,
				blockId: blockId,
				title: '',
				blockNumber: index + 1,
				comment: '',
				answers: []
			}).then((res) => {
				res.data.blockId = blockId;
				this.addQuestionForBlock(res.data, blockIndex);
				this.disabledtnAddQuestion = false;
			});
		},
		delQuestion(anketaId, questionId, blockIndex, questIndex) {
			if (confirm('Вы уверены что хотите удалить вопрос?')) {
				deleteQuestion(this.$http, anketaId, questionId).then(() => {
					this.deleteQuestionForBlock(blockIndex, questIndex, this.$http, anketaId);
				});
			}
		},
		addAnswer(question, blockIndex, questIndex) {
			this.disabledtnAddAnswer = true;

			setAnswer(this.$http, {
				anketaQuestionId: question.id,
				order: question.answers ? question.answers.length : 0,
				title: '',
				type: 3
			}).then((res) => {
				// Добавляем список выбора да/нет
				res.data.isDependedFlag = 'Нет';
				res.data.isDepended = [
					{ title: 'Да', id: `input_${res.data.id + 1}`, checked: false },
					{ title: 'Нет', id: `input_${res.data.id + 2}`, checked: true }
				];
				this.addAnswerForQuest(res.data, blockIndex, questIndex);
				this.disabledtnAddAnswer = false;
			});
		},
		delAnswer(anketaId, answerId, blockIndex, questIndex, answerIndex) {
			if (confirm('Вы уверены что хотите удалить вопрос?')) {
				deleteAnswers(this.$http, anketaId, answerId).then(() => {
					this.deleteAnswerForQuest(blockIndex, questIndex, answerIndex);
				});
			}
		},
		getOptions(input) {
			if (input.field?.fieldName === 'parentOrg' || input.field?.fieldName === 'filial') {
				return this.optionsBoolean;
			}
			return this.typeOptions;
		},
		startDrag(item, e) {
			// console.log('startDrag', item, e);
			this.startLoc = e.clientY;
			this.dragging = true;
			this.dragFrom = item;
		},
		finishDrag(array, item, pos) {
			let res = [];
			array.splice(pos, 1);
			array.splice(this.over.pos, 0, item);
			this.over = {};

			for (let i = 0; i < array.length; i += 1) {
				res.push({
					elementId: array[i].id,
					order: i
				});
			}
			changeAnswerOrder(this.$http, res);
		},
		onDragOver(item, pos, e) {
			// console.log('куда', pos);
			const dir = this.startLoc < e.clientY ? 'down' : 'up';
			this.over = { item, pos, dir };
		},
		mutationData(obj) {
			// hasComent
			if (obj.hasComent) {
				this.hasComentOptions[0].checked = true;
			} else if (obj.hasComent === false) {
				this.hasComentOptions[1].checked = true;
			}

			// setCalculation
			if (obj.setCalculation) this.setCalculationOptions[0].checked = true;
			else if (obj.setCalculation === false) this.setCalculationOptions[1].checked = true;

			// isPublic
			if (obj.isPublic) this.isPublicOptions[0].checked = true;
			else if (obj.isPublic === false) this.isPublicOptions[1].checked = true;

			// places
			if (obj.places && obj.places.length > 0) {
				for (let i = 0; i < obj.places.length; i += 1) {
					for (let j = 0; j < this.sectionOptions.length; j += 1) {
						if (obj.places[i].id === this.sectionOptions[j].id) {
							this.sectionOptions[j].checked = true;
						}
					}
				}
			}

			// isDepended && isDependedFlag
			if (obj.questionBlocks) {
				for (let i = 0; i < obj.questionBlocks.length; i += 1) {
					if (obj.questionBlocks[i].questions) {
						for (let j = 0; j < obj.questionBlocks[i].questions.length; j += 1) {
							if (obj.questionBlocks[i].questions[j].answers) {
								for (let h = 0; h < obj.questionBlocks[i].questions[j].answers.length; h += 1) {
									if (obj.questionBlocks[i].questions[j].answers[h].dependencyQuestions && obj.questionBlocks[i].questions[j].answers[h].dependencyQuestions.length === 0) {
										obj.questionBlocks[i].questions[j].answers[h].isDependedFlag = 'Нет';
										obj.questionBlocks[i].questions[j].answers[h].isDepended = [
											{ title: 'Да', id: `input_${obj.questionBlocks[i].questions[j].answers[h].id + 1}`, checked: false },
											{ title: 'Нет', id: `input_${obj.questionBlocks[i].questions[j].answers[h].id + 2}`, checked: true }
										];
									}

									if (obj.questionBlocks[i].questions[j].answers[h].dependencyQuestions && obj.questionBlocks[i].questions[j].answers[h].dependencyQuestions.length > 0) {
										obj.questionBlocks[i].questions[j].answers[h].isDependedFlag = 'Да';
										obj.questionBlocks[i].questions[j].answers[h].isDepended = [
											{ title: 'Да', id: `input_${obj.questionBlocks[i].questions[j].answers[h].id + 1}`, checked: true },
											{ title: 'Нет', id: `input_${obj.questionBlocks[i].questions[j].answers[h].id + 2}`, checked: false }
										];

										obj.questionBlocks[i].questions[j].answers[h].dependencyObj = {
											id: obj.questionBlocks[i].questions[j].answers[h].dependencyQuestions[0].dependencyQuestionId,
											name: obj.questionBlocks[i].questions[j].answers[h].dependencyQuestions[0].dependencyQuestionName
										};
									}
								}
							}
						}
					}
				}
			}
			return obj;
		},
		get() {
			getAnketa(this.$http, this.$route.params.anketId).then((res) => {
				this.updateForm(this.mutationData(res.data));
				this.changeLoadPage(false);
			});
		},
		inlineSave: debounce(function (obj, url, flagGlobalData) {
			if (!flagGlobalData) {
				inlineSaveForUrl(this.$http, { obj, url });

				return;
			}

			inlineSaveForUrl(this.$http, { obj, url }).then((res) => {
				this.updateForm(this.mutationData(res.data), true);
			});
		}, 300),
		changeIsPublic(getForm) {
			if (getForm.isPublic === 'Да') {
				for (let i = 0; i < this.sectionOptions.length; i += 1) {
					this.sectionOptions[i].checked = false;
				}
			}

			this.inlineSave(
				{
					id: getForm.id,
					type: getForm.type.id,
					status: getForm.status,
					name: getForm.name,
					setCalculation: !getForm.setCalculation ? getForm.setCalculation : getForm.setCalculation === 'Да' ? true : false,
					comment: getForm.comment,
					isPublic: !getForm.isPublic ? getForm.isPublic : getForm.isPublic === 'Да' ? true : false,
					hasComent: !getForm.hasComent ? getForm.hasComent : getForm.hasComent === 'Да' ? true : false,
					places: getForm.isPublic === true || getForm.isPublic === 'Да' ? [] : getForm.places,
					questions: []
				},
				'Anketa/UpdateAnketa',
				true
			);
		},
		saveDependencyQuestions(anketaId, answerId, dependencyQuestionObj, answerValue, arrayDependency, blockIndex, questIndex, answerIndex) {
			if (arrayDependency.length > 0) {
				deleteDependedQuestion(this.$http, arrayDependency[0].id).then(() => {
					setDependedQuestion(this.$http, { anketaId, answerId, dependencyQuestionId: dependencyQuestionObj.id, answerValue }).then((res) => {
						this.changeDependens(res.data, blockIndex, questIndex, answerIndex);
					});
				});

				return;
			}

			setDependedQuestion(this.$http, { anketaId, answerId, dependencyQuestionId: dependencyQuestionObj.id, answerValue }).then((res) => {
				this.changeDependens(res.data, blockIndex, questIndex, answerIndex);
			});
		},
		checkIsDependedFlag(isDependedFlag, dependencyQuestions, blockIndex, questIndex, answerIndex) {
			if (isDependedFlag === 'Нет' && dependencyQuestions.length > 0) {
				deleteDependedQuestion(this.$http, dependencyQuestions[0].id).then(() => {
					this.changeDependens(null, blockIndex, questIndex, answerIndex);
				});
			}
		}
	},
	created() {
		this.changeLoadPage(true);

		getGuid(this.$http, 'Anketa/GetAnketaPlaces').then((res) => {
			for (let i = 0; i < res.data.length; i += 1) {
				this.sectionOptions.push({
					id: res.data[i].id,
					name: res.data[i].name,
					checked: false
				});
			}
		});

		getGuid(this.$http, 'Anketa/GetAllAnketaTypes').then((res) => {
			for (const key in res.data) {
				if (Object.hasOwnProperty.call(res.data, key)) {
					this.typeOptions.push({
						id: +key,
						name: res.data[key]
					});
				}
			}
		});

		this.get();
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
input[type='radio'] {
	appearance: none;
	background-color: #fff;
	width: 20px;
	height: 20px;
	border: 2px solid #a8b1ce;
	border-radius: 2px;
	display: inline-grid;
	place-content: center;
	margin-right: 6px;
}

input[type='radio']::before {
	content: '';
	width: 10px;
	height: 10px;
	transform: scale(0);
	transform-origin: bottom left;
	background-color: #fff;
	clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
}

input[type='radio']:checked::before {
	transform: scale(1);
}
input[type='radio']:checked {
	background-color: #3377ff;
	border: 2px solid #3377ff;
}
input[type='radio']:checked:disabled {
	background-color: #bec7da;
	border: 2px solid #bec7da;
}

.btn_default {
	width: 28px;
	height: 24px;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 10px;
}

.question {
	/* max-width: 937px; */
	width: 100%;
}

.answer {
	/* max-width: 600px; */
	width: 100%;
}

.add-form__radio .radio-group {
	display: flex;
	gap: 20px;
}

.add-form__input > .input-box {
	margin-bottom: 0;
}

.add-form__select > .multiselect {
	margin-bottom: 0;
}

.add-form__select .multiselect__tags {
	font-weight: 400;
	font-size: 14px;
	line-height: 50px;
	min-height: 40px;
	height: auto !important;
	max-width: 100% !important;
	width: 100%;
	border-radius: 10px;
	border: 1px solid #a8b1ce;
	padding: 0 20px;
	color: #6a6e83;
	background: initial;
	display: flex;
	align-items: center;
}

.add-form__select .multiselect__tags-wrap {
	display: flex;
	align-items: center;
	height: 40px;
	overflow: hidden;
}

.add-form__select .multiselect__tag {
	padding: 0;
	color: #6a6e83;
	margin-right: 0;
	background: transparent !important;
	overflow: visible;
}

.add-form__select .multiselect__tag-icon {
	display: none;
}

.add-form__select .multiselect__tag:not(:first-child)::before {
	content: ',';
	margin-right: 5px;
}

.add-form__select .multiselect__option--highlight:after {
	display: none;
}

.add-form__select .multiselect__select {
	height: 40px;
}

.add-form__select .multiselect__placeholder {
	margin-bottom: 3px !important;
}

.add-form__select .multiselect__single {
	line-height: 18px !important;
	font-size: 14px;
	background-color: transparent;
	color: black;
}
</style>

import Vue from 'vue';

export default Vue.observable({
	form: {
		comment: '',
		created: '',
		deleted: null,
		deletedBy: null,
		hasComent: null,
		isApproved: true,
		isDeleted: false,
		isPublic: null,
		lastUpdated: '',
		name: '',
		places: null,
		questionBlocks: [],
		questions: null,
		setCalculation: null,
		status: 1,
		type: 1
	},
	loadPage: false
});

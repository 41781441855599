import state from './state';
import { deleteDependedQuestion } from './actions';

export default {
	updateQuestions(name, obj, index) {
		if (name === 'add') state.form.questions.push(obj);
		if (name === 'del') state.form.questions.splice(index, 1);
	},
	updateForm(obj, flag) {
		if (obj.type === 1) obj.type = { id: 1, name: 'Для посетителей' };
		if (obj.type === 2) obj.type = { id: 2, name: 'Для членов ОС' };
		if (obj.type === 3) obj.type = { id: 3, name: 'Для сотрудников Минздрава' };
		if (obj.type === 4) obj.type = { id: 4, name: 'Для сотрудников ОГВ' };

		if (obj.hasComent) obj.hasComent = 'Да';
		else if (obj.hasComent === false) obj.hasComent = 'Нет';

		if (obj.setCalculation) obj.setCalculation = 'Да';
		else if (obj.setCalculation === false) obj.setCalculation = 'Нет';

		if (obj.isPublic) obj.isPublic = 'Да';
		else if (obj.isPublic === false) obj.isPublic = 'Нет';

		if (!flag) {
			obj.questionBlocks.forEach((block) => {
				block.questions.sort((a, b) => a.blockNumber - b.blockNumber);
			});
			state.form = { ...obj };
		} else {
			state.form.comment = obj.comment;
			state.form.hasComent = obj.hasComent;
			state.form.isPublic = obj.isPublic;
			state.form.name = obj.name;
			state.form.places = obj.places;
			state.form.setCalculation = obj.setCalculation;
			state.form.type = obj.type;
		}
	},
	changeLoadPage(val) {
		state.loadPage = val;
	},
	addBlockWithAnket(obj) {
		if (!state.form.questionBlocks) state.form.questionBlocks = [];

		state.form.questionBlocks.push({
			questions: [],
			...obj
		});
	},
	addQuestionForBlock(obj, blockIndex) {
		state.form.questionBlocks[blockIndex].questions.push(obj);
	},
	addAnswerForQuest(obj, blockIndex, questIndex) {
		if (!state.form.questionBlocks[blockIndex].questions[questIndex].answers) state.form.questionBlocks[blockIndex].questions[questIndex].answers = [];
		state.form.questionBlocks[blockIndex].questions[questIndex].answers.push(obj);
	},
	deleteBlockForState(index) {
		state.form.questionBlocks.splice(index, 1);
	},
	deleteQuestionForBlock(blockIndex, questIndex, http) {
		state.form.questionBlocks[blockIndex].questions.splice(questIndex, 1);

		if (state.form.questionBlocks[blockIndex].questions.length === 1) {
			for (let i = 0; i < state.form.questionBlocks[blockIndex].questions[0].answers.length; i += 1) {
				if (state.form.questionBlocks[blockIndex].questions[0].answers[i].dependencyQuestions.length > 0) {
					deleteDependedQuestion(http, state.form.questionBlocks[blockIndex].questions[0].answers[i].dependencyQuestions[0].id).then((res) => {
						if (res.status === 200) {
							state.form.questionBlocks[blockIndex].questions[0].answers[i].dependencyObj = null;
							state.form.questionBlocks[blockIndex].questions[0].answers[i].isDependedFlag = 'Нет';
							state.form.questionBlocks[blockIndex].questions[0].answers[i].dependencyQuestions = [];
						}
					});
				} else {
					state.form.questionBlocks[blockIndex].questions[0].answers[i].isDependedFlag = 'Нет';
				}
			}
		}
	},
	deleteAnswerForQuest(blockIndex, questIndex, answerIndex) {
		state.form.questionBlocks[blockIndex].questions[questIndex].answers.splice(answerIndex, 1);
	},
	changeDependens(obj, blockIndex, questIndex, answerIndex) {
		if (!obj) state.form.questionBlocks[blockIndex].questions[questIndex].answers[answerIndex].dependencyObj = null;
		state.form.questionBlocks[blockIndex].questions[questIndex].answers[answerIndex].dependencyQuestions = obj ? [obj] : [];
	}
};

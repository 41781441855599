<template>
	<div class="cursor-wait" :class="[`w-${width}`, classDiv]">
		<label class="block mb-3 text-textGray font-bold text-sm leading-5">
			{{ label }}
		</label>
		<div :class="[`h-${height}`, `border-${border}`]" class="rounded-xl">
			<div class="flex animate-pulse flex-row items-center h-full" :class="{ 'justify-center': center }">
				<div v-if="type === 'select'" class="flex">
					<div class="w-36 bg-gray-300 h-6 rounded-md" />
					<div class="w-8 ml-6 bg-gray-300 h-6 rounded-md" />
				</div>

				<div v-if="type === 'input'" class="flex">
					<div class="w-72 bg-gray-300 h-6 rounded-md" />
				</div>

				<div v-if="type === 'btn'" class="flex">
					<div class="w-36 bg-gray-300 h-6 rounded-md" />
				</div>

				<div v-if="type === 'radiobtn'" class="flex">
					<div class="w-6 bg-gray-300 h-6 rounded-md" />
					<div class="ml-6 w-6 bg-gray-300 h-6 rounded-md" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		width: {
			type: Number,
			default: () => 0
		},
		height: {
			type: Number,
			default: () => 0
		},
		label: {
			type: String,
			default: () => ''
		},
		type: {
			type: String,
			default: () => ''
		},
		classDiv: {
			type: String,
			default: () => ''
		},
		center: {
			type: Boolean,
			default: () => false
		},
		border: {
			type: String,
			default: () => ''
		}
	}
};
</script>
